import React, { useRef, useState,useEffect } from 'react'
import './index.css'
const Navbar = () => {

const ref = useRef()
const [sticky,setSticky] = useState(true)


useEffect(() => {
  const stickyChange = () => {


    if (window.innerHeight/2 <= window.pageYOffset) {
  
      setSticky(false)

    }
    if (window.innerHeight/5 >= window.pageYOffset) {
      setSticky(true)
  
    }
  };
  document.addEventListener('scroll',stickyChange, )
}, [ ref ]);


return (
<nav className="sticky-top scroll-nav" ref={ref} id="nav">
    <input type="checkbox" id="check" />
    <label htmlFor="check" className="checkbtn">
      <i className="fa-solid fa-bars"></i>
    </label>
    {sticky === true &&
    <label className="logo" id="sticky">
    <h2  style={{'color':'#fff','fontSize': '22px'}}>Uzm. Dr. GAMZE SÖNMEZ AVAROĞLU</h2>
    <h2  style={{'color':'#fff','fontSize': '22px'}}>Psikiyatrist & Psikoterapist</h2>
  </label>

}

  
    <ul id="links">
      <li><a className="active" href="/">Anasayfa</a></li>
      <li><a href="/blog-listesi/">Blog</a></li>
      <li><a href="/randevu/">Randevu</a></li>
      <li><a href="/hakkimizda/">Hakkımızda</a></li>
      <li><a href="https://www.instagram.com/psikiyatrist.dr.gamze.avaroglu/"><i className="fa-brands fa-instagram fa-xl"></i> </a></li>
      <li><a href="https://wa.me/905426083448" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp fa-xl"></i> </a></li>
      <li><a href="tel:+905426083448"><i className="fa-solid fa-phone-volume fa-xl"></i> </a></li>
      <li><a href="https://www.doktortakvimi.com/gamze-sonmez-avaroglu/psikiyatri/mugla" target="_blank" rel="noreferrer"><i className="fa fa-globe fa-xl" aria-hidden="true"></i> </a></li>

    </ul>
  </nav>

  )
}

export default Navbar