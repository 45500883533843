import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
const BlogCreate = () => {
    const [caption,setCaption] = useState('')
    const user = 1
    const [article,setArticle] = useState('')
    const [image,setImage] = useState('')
    const [upload,setUpload] = useState(false)
    const navigate=useNavigate()
    const FileChange=(e)=>{
        setImage(e.target.files[0]);
         setUpload(true)
        } 
            

    const HandleSubmit = (event)=>{
        event.preventDefault();
        
        const data = new FormData();
        data.set(user,user)
        data.set(caption,caption)
        data.set(article,article)
        if (upload == true) {  
            data.append(image,image)

            fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-ekle/`,{
            method: 'post',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            body: JSON.stringify({ user: user,
            caption:caption,
            image:image,
            article:article
           })        
        })        
        .then(res=>res.json())
        .then(response=>console.log(response))
        .catch(error=>console.log(error))
    }
    else{
        fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-ekle/`,{
            method: 'post',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }, 
            body: JSON.stringify({ user: user,
            caption:caption,
            article:article
           })        
        })        
        .then(res=>res.json())
        .then(response=>{alert('Başarıyla Kaydedildi');navigate(-1)})
        .catch(error=>console.log(error))

    }
    }
    return (
    <div className='container'>
        <h2 className="caption1 mt-3">Blog Oluştur</h2>
        <form onSubmit={HandleSubmit} encType='multipart/form-data'>
        <div className='article-input'>
            <p>Başlık*</p>    
            <input type='text' required onChange={(e)=>setCaption(e.target.value)}/> 
        </div>
        <div className='article-input'>
        <p>Makale*</p>    
        <CKEditor   
                    editor={ ClassicEditor }
                    data=""
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setArticle(data)                        
                    } }
                />
        </div>
{/*         <div className='article-input'>
            <input type="file" onChange={FileChange} />
        </div>
        
 */}     
            <div className='article-button'>
                <button type='submit'>Kaydet</button>                
            </div>
        </form>
    </div>
  )
}

export default BlogCreate