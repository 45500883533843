import React from 'react'
import Footer from './Footer'
const Online = () => {
  return (
    <div className='container'>
      <h2 className="caption1">Randevu Formu</h2>

        <div className="col-12">
          <form method="post">
            <div className="row justify-content-center">
              <div className='col-12 mb-3'>
                <input style={{width : '100%',padding:'5px'}} type='text' placeholder='İsim Soyisim' />
            </div>
            <div className='col-12 mb-3'>
            <input style={{width : '100%',padding:'5px'}} type='email' placeholder='E-posta Adresi' />
            </div>
            <div className='col-12 mb-3'>
            <input style={{width : '100%',padding:'5px'}}  type='text' placeholder='Randevu Talep Tarihi ve Saati' />
            </div>
            <div className='col-12 mb-3'>
            <input style={{width : '100%',padding:'5px'}}  type='text' placeholder='Telefon Numarası' />
            </div>
            <div className='col-12 mb-3'>

            <textarea style={{width : '100%',height:'100px', resize:'none',padding:'5px'}} placeholder='Mesajınız'/>
            </div>
  
                <div className="d-flex flex-row-reverse mr-4">
                  <button type="submit" className="btn btn-info">Gönder</button>
                </div>
              </div>
          </form>
        </div>
        <Footer/>

      </div>

    
  )
}

export default Online