import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'


const BlogDetail = () => {
const {slug} = useParams()
const [article,setArticle] = useState([])
const navigate = useNavigate()

useEffect(() => {
    const getDatas = async () => {
        const response = await fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-detay/${slug}/`);
        const data = await response.json();
        setArticle(data);
        console.log(data)
    }
    getDatas()
},[]);

const History=()=>{
    navigate(-1);
    
}

return (
<div className='container'>
    <button className="back-button" onClick={History} >Geri dön</button>

    <div className="row">
      <div className="col-12 blog-header">
        <img style= {{height: '20rem', width: '100%',objectFit: 'contain', borderRadius: '15px'}}
          src={article.image}
          alt="Card image cap"
        />
      </div>
      <div className="col-12">
        <h2 className="caption1">{article.caption}</h2>
        <small>{article.date}</small>
    
        <p className="article" dangerouslySetInnerHTML={{__html:article.article}}></p>
        
      </div>
    </div>
    </div>
  )
}

export default BlogDetail