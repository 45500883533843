import React from 'react';
import { createRoot } from 'react-dom/client';
import Navbar from './Navbar';
import HomePage from './HomePage';
import BlogList from './BlogList';
import Online from './Online';
import About from './About';
import BlogDetail from './BlogDetail';
import Login from './Login';
import BlogCreate from './BlogCreate';
import BlogUpdate from './BlogUpdate';
import BlogDelete from './BlogDelete';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function Page() {
  return (
    <div>

      <BrowserRouter>        
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/giris/" element={<Login />} />
          <Route path="/blog-listesi/" element={<BlogList />} />
          <Route path="/blog-olustur/" element={<BlogCreate />} />
          <Route path="/blog/:slug/" element={<BlogDetail />} />
          <Route path="/blog-guncelle/:slug/" element={<BlogUpdate />} />
          <Route path="/randevu/" element={<Online />} />
          <Route path="/blog-sil/:slug/" element={<BlogDelete />} />
          <Route path="/randevu/" element={<Online />} />
          <Route path="/hakkimizda/" element={<About />} />
  
        </Routes>
        
      </BrowserRouter>


  </div>



  )
}


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Page/>);





