import React from 'react'
import about from './image/about.jpg';
import Footer from './Footer';
const About = () => {
  return (
<div className='container'>
<div className="row">
    <div className="col-12">
      <h2 className="caption1 text-center">Hakkımda</h2>
    </div>
    <div className="col-12 col-lg-5">
      <img style={{width: '100%'}} src={about} alt="about"/>
    </div>
    <div className="col-12 col-lg-7">
      <p className="p2" style={{textIndent: '30px'}}>
        Yirmi dört yıldır hekimlik görevini yerine getirmekte olan Gamze Sönmez Avaroğlu,
        Sivas’ta ilköğrenimine başlamış, Nişantaşı Anadolu Lisesi’ni bitirmiş ve Trakya Üniversitesi
        Tıp Fakültesi’nden mezun olmuştur 1998’ de Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve
        Sinir Hastalıkları Eğitim ve Araştırma Hastanesi’nde asistanlık eğitimine başlamış, uzman
        olduktan sonra 2004’te Başasistan olarak Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve
        Sinir Hastalıkları Eğitim ve Araştırma Hastanesi’nde çalışmaya devam etmiştir.
      </p>
      <p className="p2" style={{textIndent: '30px'}}>
        1998-2008 Tarihleri arasında PSYCHO-MED İlişki Psikoterapileri Enstitüsü Aile ve Çift
        Terapisi Eğitimi, CETAD (Cinsel Eğitim Tedavi ve Araştırma Derneği) tarafından verilen Cinsel
        İşlev Bozuklukları Eğitimi ve ayrıca Bireysel Terapi ve Grup Terapisi Alanında Eğitimlerini
        tamamlamıştır.
      </p>
      <p className="p2" style={{textIndent: '30px'}}>
        Devamında Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve Sinir Hastalıkları Eğitim ve
        Araştırma Hastanesi’nde aile ve çift terapisi polikliniği, cinsel işlev bozuklukarı polikiniği ve
        Adli Psikiyatri Birimi’nde çalışmış , Lütfiye Nuri Burat Devlet Hastanesine geçmiş ve 2014
        yılında Haseki Eğitim ve Araştırma Hastanesi Fatih Toplum Ruh Sağlığı Merkezi kurucu hekim
        olarak çalışmıştır. Bipolar Bozukluk, şizofreni, depresyon, atipik psikoz, anksiyete bozuklukları
        gibi birçok alanda çalışmıştır.
      </p>
      <p className="p2" style={{textIndent: '30px'}}>
        Mesleki hayatı boyunca birçok önemli eğitime katılmış olan Avaroğlu, 2019 itibariyle
        Muğla Milas 75. Yıl Devlet Hastanesi’nde göreve başlamış ardından Bodrum Devlet
        hastanesi’ne geçmiştir.
      </p>
      <p className="p2" style={{textIndent: '30px'}}>

        Şu anda çalışmalarına özel muayenehanesinde devam etmekte, tecrübe ve birikimierini
        Bodrum’daki danışanları ile paylaşmaktadır.</p>
    </div>
  </div>
  <Footer/>

  </div>

  )
}

export default About