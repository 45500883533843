import React, { useEffect, useState } from 'react'
import logo from './image/logo.png'
const BlogList = () => {
const [articles,setArticles]=useState([])


useEffect(() => {
    const getDatas = async () => {
        const response = await fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-listesi/`);
        const data = await response.json();
        setArticles(data);
        console.log(data)
    }
    getDatas()
},[]);
return (
<div className='container'>
{localStorage.getItem('token') !== null && localStorage.getItem('token')!== 'undefined' &&
  <div className="superuser">
    <a className="back-button" href="/blog-olustur/">Blog Oluştur</a>
  </div>    
}
    <div className="row">
      <div className="col-12 blog-header">
    <h2 className="caption1">Blog Listesi</h2>
    </div>
    {articles.map((article,index)=>
        
    <div key={index} className="col-12 col-md-6 col-lg-4 article-href" style={{marginBottom: '100px'}}>
      <a href={`/blog/${article.slug}/`}>
        <div className="card-body">
    <h5
      className="card-title"
      style={{textAlign: 'center', color:' black',fontWeight: '900',height:'80px',fontSize:'1.6rem'}}
    >
      {article.caption}
    </h5>
      </div>
      <div className="card border-0" style={{width: '100%'}}>
        <img
          style={{height: '20rem', objectFit:'cover', borderRadius: '15px'}}
          src={article.image}
          alt="Card image cap"
        />
             <small>{article.date}</small>
   
      </div>
    </a>
    {localStorage.getItem('token') !== null && localStorage.getItem('token')!== 'undefined' &&
      <div className="buttons">
      <a className="btn btn-info" href={`/blog-guncelle/${article.slug}/`}
        >Düzenle</a>
      <a className="btn btn-danger" href={`/blog-sil/${article.slug}/`}
        >Sil</a
      >
    </div>
  
    }    
    </div>

    )}
  
    </div>
  <div className="separate">
    <div className="col-12 separate-container">
      <span className="separate-banner"></span>
      <img src={logo} alt="logo" />
      <span className="separate-banner"></span>
    </div>
  </div>
  </div>
  )
}

export default BlogList