import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
const BlogUpdate = () => {
    const {slug} = useParams()
    const [article,setArticle] = useState()
    const [instance,SetInstance] = useState()
    const [caption,setCaption] = useState()
    const user = 1
    const navigate= useNavigate()    
    useEffect(()=>{
        fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-guncelle/${slug}/`)
        .then(res=>res.json())
        .then(response=>{SetInstance(response.article);setCaption(response.caption)})
        .catch(error=>console.log(error))
    },[])


    const HandleSubmit=(e)=>{
        e.preventDefault();
        const data = new FormData();
        data.set(user,user)
        data.set(caption,caption)
        data.set(article,article)

        fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-guncelle/${slug}/`,{
        method: 'patch',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: user,
        caption:caption,
        article:article
           })        
        })        
        .then(res=>res.json())
        .then(response=>{alert('Başarıyla Güncellendi.');navigate(-1)})
        .catch(error=>console.log(error))
    

    }
    return (
        <div className='container'>
        <h2 className="caption1 mt-3">Blog Güncelle</h2>
        <form onSubmit={HandleSubmit} >
        <div className='article-input'>
            <p>Başlık*</p>    
            <input defaultValue={caption} type='text' required onChange={(e)=>setCaption(e.target.value)}/> 
        </div>
        <div className='article-input'>
        <p>Makale*</p>    
        <CKEditor   
                    editor={ ClassicEditor }
                    data={instance}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setArticle(data)                        
                    } }
                />
        </div>
            <div className='article-button'>
                <button type='submit'>Kaydet</button>                
            </div>
        </form>
    </div>

  )
}

export default BlogUpdate