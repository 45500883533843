import React, { useEffect, useState } from 'react'
import logo from './image/logo.png'
const BlogListShort = () => {
const [articles,setArticles]=useState([])


useEffect(() => {
    const getDatas = async () => {
        const response = await fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-listesi-kisa/`);
        const data = await response.json();
        setArticles(data);
        console.log(data)
    }
    getDatas()
},[]);


return (
<div className='container'>    
    <div className="row">
    <div className="col-12">
      <h2 className="caption1">Blog Yazıları</h2>
      <div className="col-12">
      <h2 className="text-center">25 Yıllık Deneyim...</h2>
      <p className="text-center">
        Uzm. Dr. Gamze Sönmez Avaroğlu çalışmalarına özel muayenehanesinde devam etmekte, tecrübe ve birikimini Bodrum 'daki danışanları ile paylaşmaktadır. Uzmanlık alanları ile ilgili yazdığı yazılara ulaşmak için hemen tıklayın.
      </p>
    </div>

      <p className="text-center">
        <a className="contact-button" href="/blog-listesi/">Hepsini Gör</a></p>
    </div>
    {articles.map((article,index)=>
        
    <div key={index} className="col-12 col-md-6 col-lg-4 article-href" style={{marginBottom: '100px'}}>
      <a href={`/blog/${article.slug}/`}>
        <div className="card-body">
    <h5
      className="card-title"
      style={{textAlign: 'center', color:' black',fontWeight: '900',height:'80px',fontSize:'1.6rem'}}
    >
      {article.caption}
    </h5>
      </div>
      <div className="card border-0" style={{width: '100%'}}>
        <img style={{height: '20rem', objectFit:'cover', borderRadius: '15px'}}
          src={article.image}
          alt={article.caption}
        />
      </div>
    </a>
    </div>

    )}
  
    </div>
  <div className="separate">
    <div className="col-12 separate-container">
      <span className="separate-banner"></span>
      <img src={logo} alt="logo" />
      <span className="separate-banner"></span>
    </div>
  </div>
  </div>
  )
}

export default BlogListShort