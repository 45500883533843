import React from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const BlogDelete = () => {
    const {slug} = useParams()
    const navigate = useNavigate()
    const HandleSubmit = ()=>{
        fetch(`https://gamzesonmez.pythonanywhere.com/bloglar/blog-sil/${slug}/`,{
            method: 'DELETE'})
            alert('Başarıyla Silindi.')
            navigate(-1)        
            .catch(error=>console.log(error))                
    }
    return (
    <div className='container'>
        <h2 className='text-danger text-center mt-5'>
            Silmek İstediğinize Emin Misiniz?
        </h2> 
        <div style={{display:'flex',justifyContent:'center'}}>
            <button className='btn btn-danger' onClick={HandleSubmit}>Evet</button>
        </div>

    </div>
    )
}

export default BlogDelete