import React from 'react'
import logo from './image/logo.png';
import kartvizit from './image/kartvizitarka.jpeg';
const Footer = () => {
  return (

<div className="container">
    <div className="separate">
      <div className="col-12 separate-container">
        <span className="separate-banner"></span>
        <img src={logo} alt="logo" />
        <span className="separate-banner"></span>
      </div>
    </div>
  <div className="row footer">
    <div className="col-12 d-flex justify-content-center">
      <img src={kartvizit} alt="kartvizit"/>
    </div>
    <div className="col-12">
      <h4 className="text-center">Uz. Dr. Gamze Sönmez Avaroğlu</h4>
      <h5 className="text-center">Psikiyatrist Psikoterapist</h5>
      <h5 className="text-center">Müskebi Mahallesi Kapuz Caddesi AVN  İş Merkezi No:31 L Blok D:4 (Acıbadem Hastanesi Acil Karşısı) Ortakent/Bodrum/Muğla</h5>
      <h5 className="text-center"><a href="tel:+905426083448"> +90 542 608 34 48</a></h5>

      <div className="d-flex justify-content-center mb-4">
        <h5 className="text-center mr-5"><a href="https://www.instagram.com/psikiyatrist.dr.gamze.avaroglu/"><i className="fa-brands fa-instagram fa-xl"></i></a></h5>
        <h5 className="text-center"><a href="https://wa.me/905426083448" target="_blank"><i className="fa-brands fa-whatsapp fa-xl"></i></a></h5>
      </div>

    </div>
    <div className="col-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.0578741207833!2d27.354732!3d37.056104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e967fcdf1016ba6!2sPsikiyatrist%20Psikoterapist%20Uz.Dr.%20Gamze%20S%C3%B6nmez%20Avaro%C4%9Flu!5e0!3m2!1str!2str!4v1662072498174!5m2!1str!2str" width="100%" height="450" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>    </div>
  <p className="text-center">Bu sitede paylaşılan bilgiler sadece bilgilendirme amaçlıdır, hiç bir tanı ve tedavi amacı taşımaz ve bu amaçla kullanılmamalıdır. Tıbbi tanı, tedavi ve her türlü sağlık hizmeti için mutlaka lisanslı bir sağlık profesyoneline danışılmalıdır.</p>

    </div>

  </div>
  
  )
}

export default Footer