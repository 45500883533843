import React,{useEffect} from 'react';
import slide6 from './image/slide6.jpeg';
import slide2 from './image/slide2.jpeg';
import slide4 from './image/slide4.jpeg';
import slide7 from './image/slide7.jpeg';
import logo from './image/logo.png';
import about from './image/about.jpg';
import depression from './image/depression.jpeg';
import anxiety from './image/anxiety.jpeg';
import family from './image/family.jpeg';
import sexual from './image/sexual.jpeg';
import bipolar from './image/bipolar.jpeg';
import schizophrenia from './image/schizophrenia.jpeg';
import alchol from './image/alchol.jpeg';
import insomnia from './image/insomnia.jpeg';
import burnout from './image/burnout.jpeg';
import eating from './image/eating.jpeg';
import somatizasyon from './image/somatizasyon.jpeg';
import personality from './image/personality.jpeg';
import bored from './image/bored.jpeg';
import old from './image/old.jpeg';
import puberty from './image/puberty.jpeg';
import bullying from './image/bullying.jpeg';
import behavior from './image/behavior.jpeg';
import learning from './image/learning.jpeg';
import online from './image/online.jpeg';
import randevu from './image/online.png';
import booking from './image/booking.png';

import BlogListShort from './BlogListShort';
import Online from './Online';
import Footer from './Footer';


const HomePage = () => {
  useEffect(() => {
    setInterval(function () {
    document.getElementById('next-button').click();
   }, 5000); }, [])
return (
<>
<div className="container-fluid">

  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" >
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>

  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img style={{height: '550px', objectFit: 'cover'}} className="d-block w-100" src={slide6} alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img style={{height: '550px', objectFit: 'cover'}} className="d-block w-100" src={slide2} alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img style={{height: '550px', objectFit: 'cover'}} className="d-block w-100" src={slide7} alt="Third slide"/>
    </div>
    <div className="carousel-item">
      <img style={{height: '550px', objectFit: 'cover'}} className="d-block w-100" src={slide4} alt="Four slide"/>
    </div>

  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon"  aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" id='next-button' href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
</div>
      <div className="container">
      <div className="separate">
        <div className="col-12 separate-container">
          <span className="separate-banner"></span>
          <img src={logo} alt="logo" />
          <span className="separate-banner"></span>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="caption1 text-center">Hakkımda</h2>
        </div>
        <div className="col-12 col-lg-5">
          <img style={{width: '100%'}} src={about} alt="about"/>
        </div>
        <div className="col-12 col-lg-7">
          <p className="p2" style={{textIndent: '30px'}}>
            Yirmi dört yıldır hekimlik görevini yerine getirmekte olan Gamze Sönmez Avaroğlu,
            Sivas’ta ilköğrenimine başlamış, Nişantaşı Anadolu Lisesi’ni bitirmiş ve Trakya Üniversitesi
            Tıp Fakültesi’nden mezun olmuştur 1998’ de Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve
            Sinir Hastalıkları Eğitim ve Araştırma Hastanesi’nde asistanlık eğitimine başlamış, uzman
            olduktan sonra 2004’te Başasistan olarak Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve
            Sinir Hastalıkları Eğitim ve Araştırma Hastanesi’nde çalışmaya devam etmiştir.
          </p>
          <p className="p2" style={{textIndent: '30px'}}>
            1998-2008 Tarihleri arasında PSYCHO-MED İlişki Psikoterapileri Enstitüsü Aile ve Çift
            Terapisi Eğitimi, CETAD (Cinsel Eğitim Tedavi ve Araştırma Derneği) tarafından verilen Cinsel
            İşlev Bozuklukları Eğitimi ve ayrıca Bireysel Terapi ve Grup Terapisi Alanında Eğitimlerini
            tamamlamıştır.
          </p>
          <p className="p2" style={{textIndent: '30px'}}>
            Devamında Bakırköy Prof. Dr. Mazhar Osman Ruh Sağlığı ve Sinir Hastalıkları Eğitim ve
            Araştırma Hastanesi’nde aile ve çift terapisi polikliniği, cinsel işlev bozuklukarı polikiniği ve
            Adli Psikiyatri Birimi’nde çalışmış , Lütfiye Nuri Burat Devlet Hastanesine geçmiş ve 2014
            yılında Haseki Eğitim ve Araştırma Hastanesi Fatih Toplum Ruh Sağlığı Merkezi kurucu hekim
            olarak çalışmıştır. Bipolar Bozukluk, şizofreni, depresyon, atipik psikoz, anksiyete bozuklukları
            gibi birçok alanda çalışmıştır.
          </p>
          <p className="p2" style={{textIndent: '30px'}}>
            Mesleki hayatı boyunca birçok önemli eğitime katılmış olan Avaroğlu, 2019 itibariyle
            Muğla Milas 75. Yıl Devlet Hastanesi’nde göreve başlamış ardından Bodrum Devlet
            hastanesi’ne geçmiştir.
          </p>
          <p className="p2" style={{textIndent: '30px'}}>

            Şu anda çalışmalarına özel muayenehanesinde devam etmekte, tecrübe ve birikimierini
            Bodrum’daki danışanları ile paylaşmaktadır.</p>
        </div>
      </div>
      </div>
      <div className="container">
      <div className="separate">
        <div className="col-12 separate-container">
          <span className="separate-banner"></span>
          <img src={logo} alt="logo" />
          <span className="separate-banner"></span>
        </div>
      </div>
        <h2 className="caption1">Nihayetinde İnsanız</h2>
          <p className="p1" style={{textIndent: '30px'}}>
            İnsanlar zaman zaman bazı olumsuz olay ve durumlarla karşılaşıp bu durumlardan farklı şekilde etkilenebilirler.
          </p>
          <p className="p1" style={{textIndent: '30px'}}>
            Davranış ve baş etme süreçleri kişiden kişiye farklılık gösterir. Yaşanılan durumun kişide nasıl bir etki yaratacağı, nelere sebep olabileceği, kişiye, olayın oluşuna ve kişide nelerin tetiklendiğine bağlı olarak değişebilir.
            Bir olay bazı insanlarda anlık bir tepkiye neden olurken bir başkasında çok daha büyük bir travmaya yol açabilir.
            Bazı dönemlerde, birey,  kaygı, endişe, öfke,  umutsuzluk, mutsuzluk, çaresizlik, takıntı gibi durumlarla baş etmekte ve belki de durumun ciddiyetinin farkına varmakta zorlanabilir.
            Hatta kişinin sıkıntıları en yakınları ve sosyal çevresi tarafından da fark edilmemiş olabilir.
          </p>
          <p className="p1" style={{textIndent: '30px'}}>
                Psikiyatriste gitmek bir yetersizlik değil sanılanın aksine ihtiyaçtır.
          </p>
          <p className="p1" style={{textIndent: '30px'}}>
                Sıkıntılı dönemlerde mutlaka bir uzmandan destek alınmalı, gerekli tetkikler  yapılarak, kişiye uygun yöntem belirlenip, terapi ve/veya ilaçla tedavi yoluna gidilmelidir.
          </p>


      <div className="separate">
        <div className="col-12 separate-container">
          <span className="separate-banner"></span>
          <img src={logo} alt="logo" />
          <span className="separate-banner"></span>
        </div>
      </div>
      <h2 className="caption1">Çalışma Alanlarım</h2>
      <div className="row">
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={depression} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Depresyon</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" style={{height:'233.28px'}} src={anxiety} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Anksiyete Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={family} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Aile ve Çift Terapisi</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={sexual} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Cinsel İşlev Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
        <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" style={{height:'233.28px'}} src={bipolar} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Bipolar Bozukluk</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
        <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={schizophrenia} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Şizofreni ve Diğer Psikotik Bozukluklar</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
        <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={alchol} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Alkol Kullanım Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
        <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={insomnia} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Uyku Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
        <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={burnout} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Tükenmişlik Sendromu</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={eating} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Yeme Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={somatizasyon} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Somatizasyon (Bedensel Belirti) Bozukluğu</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={personality} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Kişilik Bozuklukları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={bored} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Erişkin ve Ergen Dikkat Eksikliği ve Hiperaktivite Bozukluğu</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={old} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Geriatrik Psikiyatri( Yaşlılık Döneminde Görülen Ruhsal Bozuklukları)</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={puberty} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Ergenlik Problemleri</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={bullying} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Akran Zorbalıkları</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={behavior} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Davranış Bozukluğu</h5>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mb-3">
          <div className="card card-effect border-0" style={{width: '100%', height: 'fitContent'}}>
            <img className="card-img-top" src={learning} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title text-center mt-2">Öğrenme Güçlüğü</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="separate">
        <div className="col-12 separate-container">
          <span className="separate-banner"></span>
          <img src={logo} alt="" />
          <span className="separate-banner"></span>
        </div>
      </div>
      </div>
  <BlogListShort/>
<div className='container'>

  <h2 className="caption1">Online Terapi</h2>
      <div className="row">
        <div className="col-12 mb-4">
          <img style={{width: '100%',height: '450px', objectFit: 'cover'}} src={online} alt="online-randevu"/>
        </div>
        <div className="col-sm-12 col-lg-6 mb-2">
          <img style={{width: '50px', height:'50px'}} src={randevu} alt="online"/>
          <h4>Online Terapi Talepleriniz İçin Bize Ulaşabilirsiniz</h4>
          <p>Telefon, Whatsapp veya Randevu Formu Doldurarak Bizimle İletişime Geçebilirsiniz.</p>
        </div>
        <div className="col-sm-12 col-lg-6 mb-2">
          <img style={{width: '50px', height:'50px'}} src={booking} alt="booking"/>
          <h4>Belirlediğimiz Tarihte Terapi Planınızı Oluşturalım</h4>
          <p>Sonrasında Yeni Bir Randevu Tarihi İçin Ortak Bir Karara Varıyoruz.</p>
        </div>
        <div className="col-12">
          <h4 className="text-center">Herkesin Terapiye İhtiyacı Olabilir.</h4>
          <p className="text-center">Mekan sınırlandırılmasına maruz kalmadan, dilediğiniz yerden online terapi alabilirsiniz.</p>
        </div>
        <div className="col-12 mt-3">
          <p className="text-center">
            <a className="contact-button"  href="/randevu/">Randevu Oluştur</a>
          </p>
    </div>
  </div>

  </div>
  <Online/>
  <Footer/>
  </>
)
}

export default HomePage



